import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Tab, Nav, Modal } from 'react-bootstrap';
import './ViewAllServices.css';
import SearchServices from '../features/SearchServices';
import PaginationComponent from '../features/Pagination';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPrint, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import Invoice from './Invoice';
import ExtraInvoice from './ExtraInvoice';
import { TbFileInvoice } from "react-icons/tb";

const ViewAllServicesLM = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage] = useState(5);
  const [selectedService, setSelectedService] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentType, setPaymentType] = useState('Cash'); // New state for payment type
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await fetch(`${BASE_URL}/service`);
      if (!response.ok) {
        throw new Error('Failed to fetch services');
      }
      const servicesData = await response.json();

      const servicesWithCustomers = await Promise.all(
        servicesData.map(async (service) => {
          try {
            if (service.customer_id) {
              const customerResponse = await fetch(`${BASE_URL}/customers/${service.customer_id}`);
              if (!customerResponse.ok) {
                throw new Error(`Failed to fetch customer for customer ID ${service.customer_id}`);
              }
              const customerData = await customerResponse.json();

              if (customerData.data && customerData.data.firstName && customerData.data.lastName) {
                return {
                  ...service,
                  customerName: `${customerData.data.firstName} ${customerData.data.lastName}`,
                  customerEmail: `${customerData.data.email}`,
                  customerContactNumber: `${customerData.data.contactNo}`,
                };
              } else {
                throw new Error(`Invalid customer data for customer ID ${service.customer_id}`);
              }
            } else {
              throw new Error(`Customer ID not found for service ID ${service._id}`);
            }
          } catch (error) {
            console.error('Error fetching customer data:', error);
            return {
              ...service,
              customerName: 'Unknown'
            };
          }
        })
      );
      servicesWithCustomers.sort((a, b) => new Date(b.createdAtService) - new Date(a.createdAtService));
      setServices(servicesWithCustomers);
    } catch (error) {
      setError(error.message || 'Failed to fetch services');
    } finally {
      setLoading(false);
    }
  };

  const generateInvoice = (id, type) => {
    const service = services.find(service => service.service_id === id);
    if (service) {
      setSelectedService(service);
      setInvoiceType(type);
      setShowInvoiceModal(true);
    }
  };

  const handleCloseInvoice = () => {
    setSelectedService(null);
    setShowInvoiceModal(false);
    setInvoiceType('');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowDeleteModal = (id) => {
    setServiceToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setServiceToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    if (serviceToDelete) {
      try {
        const response = await fetch(`${BASE_URL}/service/delete/${serviceToDelete}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          const updatedServices = services.filter(service => service.service_id !== serviceToDelete);
          setServices(updatedServices);
          toast.success('Service deleted successfully');
        } else {
          throw new Error('Failed to delete service');
        }
      } catch (error) {
        console.error('Error deleting service:', error);
        toast.error('Failed to delete service. Please try again.');
      } finally {
        setServiceToDelete(null);
        setShowDeleteModal(false);
      }
    }
  };

  // Get current month and year
  const now = new Date();
  const currentMonth = now.getMonth(); // 0-based month (0 = January)
  const currentYear = now.getFullYear();

  // Filter services based on the current month, year, search term, and payment type
  const filteredServices = useMemo(() =>
    services.filter(service => {
      const serviceDate = new Date(service.createdAtService);
      return (
        serviceDate.getMonth() === currentMonth &&
        serviceDate.getFullYear() === currentYear &&
        service.paymentType === paymentType // Filter by payment type
      );
    }).filter(service =>
      (service.type && service.type.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (service.vehicle_no && service.vehicle_no.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (service.desc && service.desc.toLowerCase().includes(searchTerm.toLowerCase()))
    ), [services, searchTerm, currentMonth, currentYear, paymentType]
  );

  // Pagination
  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);
  const totalPages = Math.ceil(filteredServices.length / servicesPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-all-services">
      <h2 className="text-center">All Services - {now.toLocaleString('default', { month: 'long' })} {currentYear}</h2>

      <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {/* Tabs for payment type */}
      <Tab.Container defaultActiveKey="Cash">
        <Nav variant="tabs" className="justify-content-center">
          <Nav.Item>
            <Nav.Link eventKey="Cash" onClick={() => setPaymentType('Cash')}>Cash</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Credit" onClick={() => setPaymentType('Credit')}>Credit</Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Service ID</th>
            {/* <th>Type</th> */}
            <th>Vehicle Number</th>
            <th>Created Date</th>
            <th>Total Amount</th>
            <th>Customer Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentServices.map(service => (
            <tr key={service.service_id}>
              <td>{service.service_id}</td>
              {/* <td>{service.type}</td> */}
              <td>{service.vehicle_no}</td>
              <td>{new Date(service.createdAtService).toLocaleString()}</td>
              <td>Rs. {service.totalExtraAmount}.00</td>
              <td>{service.customerName}</td>
              <td>
                <Link to={`/update/${service.service_id}`} className="action-button">
                  <Button variant="warning" className="mr-2"><FaEdit /></Button>
                </Link>
                <Link to={`/view/${service.service_id}`} className="action-button">
                  <Button variant="primary" className="mr-2"><FaEye /></Button>
                </Link>
                {service.service_status === 'Completed' && (
                  <>
                    <Button
                      variant="success"
                      onClick={() => generateInvoice(service.service_id, 'invoice')}
                      className="action-button"
                    >
                      <FaPrint className="mr-2" />
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => generateInvoice(service.service_id, 'extraInvoice')}
                      className="action-button"
                    >
                      <TbFileInvoice className="mr-2" />
                    </Button>
                  </>
                )}
                <Button
                  variant="danger"
                  onClick={() => handleShowDeleteModal(service.service_id)}
                  className="action-button"
                >
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      <Modal show={showInvoiceModal} onHide={handleCloseInvoice} centered>
        <Modal.Header closeButton>
          <Modal.Title>{invoiceType === 'invoice' ? 'Invoice' : 'Extra Invoice'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {invoiceType === 'invoice' ? (
            <Invoice service={selectedService} />
          ) : (
            <ExtraInvoice service={selectedService} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInvoice}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this service?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewAllServicesLM;